import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AxiofinComponent } from './axiofin/axiofin.component';
import { AxiomarkComponent } from './axiomark/axiomark.component';
import { AxiologComponent } from './axiolog/axiolog.component';
import { AxioInComponent } from './axioin/axioIn.component';
import { AxioScoreComponent } from './axioscore/axioscore.component';
import { GlobalInitiativeComponent } from './globalInitiative/global-initiative.component';
import { DPOComponent } from './dpo/dpo.component';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./globalInitiative/global.initiative.module').then(m =>     
      m.GlobalInitiativeModule)
  },
  {
    path: 'axiofin',
    loadChildren: () => import('./axiofin/axiofin.module').then(m =>     
      m.AxioFinModule)
  },
  {
    path: 'axiomark',
    loadChildren: () => import('./axiomark/axiomark.module').then(m =>     
      m.AxioMarkModule)
  },
  {
    path: 'axiolog',
    loadChildren: () => import('./axiolog/axiolog.module').then(m =>     
      m.AxioLogModule)
  },
  {
    path: 'axioin',
    loadChildren: () => import('./axioin/axioin.module').then(m =>     
      m.AxioInModule)
  },
  {
    path: 'axioscore',
    loadChildren: () => import('./axioscore/axioscore.module').then(m =>     
      m.AxioScoreModule)
  },
  {
    path: 'dpo',
    loadChildren: () => import('./dpo/dpo.module').then(m =>     
      m.DPOModule)
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
