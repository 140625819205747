import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AxiofinComponent } from './axiofin/axiofin.component';
import { NavBarComponent } from './nav-bar/nav-bar.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatToolbarModule } from '@angular/material/toolbar';
import { AxiomarkComponent } from './axiomark/axiomark.component';
import { AxiomarkPageComponent } from './axiomark/axiomark_page_2.component';
// import { AxiomarkSixStepsComponent } from './axiomark/sixsteps.component';
import { AxiomarkDPOComponent } from './axiomark/axiomark.dpo.component';
import { AxiomarkConclusionComponent } from './axiomark/axiomark.conclusion.component';
import { AxiomarkJoinusComponent } from './axiomark/axiomark.joinus.component';
import { AxiologComponent } from './axiolog/axiolog.component';
import { SixStepsAxioLogComponent } from './axiolog/sixstepsaxiolog.component';
import { AxiomarkSixStepsComponent } from './axiomark/axiomark.sixsteps.component';
import { AxiologDPOComponent } from './axiolog/axiolog.dpo.component';
import { AxiologUDQComponent } from './axiolog/axiolog.udq.component';
import { AxiologUgridComponent } from './axiolog/axiolog.ugrid.component';
import { AxioLogJoinusComponent } from './axiolog/axiolog.joinus.component';
import { AxioInComponent } from './axioin/axioIn.component';
import { AxioInDPOComponent } from './axioin/axioIn.dpo.component';
import { AxioInUDQComponent } from './axioin/axioIn.udq.component';
import { SixStepsAxioInComponent } from './axioin/sixstepsaxioIn.component';
import { AxioInBenefitsComponent } from './axioin/axioIn.benefits.component';
import { AxioInJoinusComponent } from './axioin/axioIn.joinus.component';
import { AxioScoreComponent } from './axioscore/axioscore.component';
import { AxioScoreUDQComponent } from './axioscore/axioscore.udq.component';
import { AxioScoreJoinusComponent } from './axioscore/axioscore.joinus.component';
import { AxioScoreBenefitsComponent } from './axioscore/axioscore.benefits.component';
// import { GlobalInitiativeSixStepsComponent } from './globalInitiative/global-initiative.sixsteps.component';
// import { GlobalInitiativeJoinusComponent } from './globalInitiative/global-initiative.joinus.component';
// import { GlobalInitiativeConclusionComponent } from './globalInitiative/global-initiative.conclusion.component';
// import { GlobalInitiativePageComponent } from './globalInitiative/global-initiative_page_2.component';
// import { GlobalInitiativeComponent } from './globalInitiative/global-initiative.component';
// import { BluePrintComponent } from './globalInitiative/blue-print.component';
// import { DPOComponent } from './dpo/dpo.component';
// import { DPOJoinusComponent } from './dpo/dpo.joinus.component';
// import { DPOSixStepsComponent } from './dpo/dpo.sixsteps.component';
// import { DPOUDQComponent } from './dpo/dpo.udq.component';
// import { DPOBenefitsComponent } from './dpo/dpo.benefits.component';
// import { DPODPOComponent } from './dpo/dpo.dpo.component';
import { AxioFinBenefitsComponent } from './axiofin/axiofIn.benefits.component';
import { AxioFinJoinusComponent } from './axiofin/axiofin.joinus.component';
import { SixStepsAxioFinComponent } from './axiofin/sixstepsaxiofin.component';
import { AxioFinUDQComponent } from './axiofin/axiofin.udq.component';
import { AxioFinDPOComponent } from './axiofin/axiofin.dpo.component';
import { GlobalInitiativeModule } from './globalInitiative/global.initiative.module';
import { DPOModule } from './dpo/dpo.module';
import { AxioMarkModule } from './axiomark/axiomark.module';
import { AxioFinModule } from './axiofin/axiofin.module';
import { AxioInModule } from './axioin/axioin.module';
import { AxioScoreModule } from './axioscore/axioscore.module';
@NgModule({
  declarations: [
    AppComponent,
    NavBarComponent,
    // AxiofinComponent,
    // AxioFinBenefitsComponent,
    // AxioFinJoinusComponent,
    // SixStepsAxioFinComponent,
    // AxioFinUDQComponent,
    // AxioFinDPOComponent,

    // AxiomarkComponent,
    // AxiomarkPageComponent,
    // AxiomarkConclusionComponent,
    // AxiomarkJoinusComponent,
    // AxiomarkDPOComponent,
    // AxiomarkSixStepsComponent,

    // AxiologDPOComponent,
    // AxiologUDQComponent,
    // AxiologUgridComponent,
    // AxioLogJoinusComponent,
    // AxiologComponent,
    // SixStepsAxioLogComponent,

    // AxioInComponent,
    // AxioInDPOComponent,
    // AxioInUDQComponent,
    // AxioInBenefitsComponent,
    // SixStepsAxioInComponent,
    // AxioInJoinusComponent,

    // AxioScoreComponent,
    // AxioScoreUDQComponent,
    // AxioScoreBenefitsComponent,
    // AxioScoreJoinusComponent,

    // GlobalInitiativePageComponent,
    // BluePrintComponent,
    // GlobalInitiativeConclusionComponent,
    // GlobalInitiativeJoinusComponent,
    // GlobalInitiativeSixStepsComponent,

    // DPOComponent,
    // DPOJoinusComponent,
    // DPOSixStepsComponent,
    // DPOUDQComponent,
    // DPOBenefitsComponent,
    // DPODPOComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatToolbarModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
